import { faFacebook, faInstagram, faLinkedin, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

export default function ContactUs(props) {

   return (
    <div id="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <div className="contact-us-text">
              <h3>تواصل معنا</h3>
              <h2>تواصل معنا للمزيد <span className="text-myPrimary"> <br />  ولطلب خدمتك.</span></h2>
              <p>{props.data ? props.data.paragraph1   : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2   : "loading..."}</p>
              <div className='contacts'>
                <h6>للتواصل معنا :</h6>
                <div className="container">
                  <div className="col-md-12">
                    <div className="row">
                      <ul className='col-md-6'>
                        <li className='my-1'>
                          <a>
                            <FontAwesomeIcon icon={faPhone}  /> 
                            <span className='ms-1'>{props.data ? props.data.contacts.phone1 : <></>}</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a>
                            <FontAwesomeIcon icon={faPhone}  /> 
                            <span className='ms-1'>{props.data ? props.data.contacts.phone2 : <></>}</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a href={`mailto:${props.data ? props.data.contacts.email : ""}`} target='_blank'>
                          <FontAwesomeIcon icon={faEnvelope}  />
                          <span className='ms-1'>{props.data ? props.data.contacts.email : <></>}</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a href={props.data ? props.data.contacts.facebook : "/"} target='_blank'>
                          <FontAwesomeIcon icon={faFacebook}  /> 
                          <span className='ms-1'>Facebook</span>
                          </a>
                        </li>
                      </ul>
                      <ul className='col-md-6'>
                        <li className='my-1'>
                          <a href={props.data ? props.data.contacts.x_twitter : "/"} target='_blank'>
                            <FontAwesomeIcon icon={faXTwitter}  /> 
                          <span className='ms-1'>X (twitter)</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a href={props.data ? props.data.contacts.tiktok : "/"} target='_blank'>
                            <FontAwesomeIcon icon={faTiktok}  /> 
                          <span className='ms-1'>tiktok</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a href={props.data ? props.data.contacts.instagram : "/"} target='_blank'>
                            <FontAwesomeIcon icon={faInstagram}  /> 
                          <span className='ms-1'>instagram</span>
                          </a>
                        </li>
                        <li className='my-1'>
                          <a href={props.data ? props.data.contacts.linkedin : "/"} target='_blank'>
                            <FontAwesomeIcon icon={faLinkedin}  /> 
                          <span className='ms-1'>linkedin</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className='map-section'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.286195504605!2d29.996362199999997!3d31.240499699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5db8c02f0f2e5%3A0x6911b6b9a71f338!2sISkandar%20advertising!5e0!3m2!1sen!2seg!4v1720387508518!5m2!1sen!2seg" width="100%" height="100%" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
