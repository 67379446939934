import React from "react";

export default function Footer(props) {
  return (
    <section>
      <div id="footer">
        <div className="container text-center">
          <p className="m-0">
            2024 &copy; ISkandar advertising copyright
          </p>
        </div>
      </div>
    </section>
  );
};
