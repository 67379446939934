import React from "react";

export default function About(props) {
  return (
    <div id="about">
      <div className="container mt-lg-5 mt-2">
        <div className="row flex-row flex-lg-row-reverse">
          <div className="col-xs-12 col-lg-6 mb-3 mb-lg-0">
            <img src="img/iskandar/logo-isk.png" className="img-responsive" alt="iskandar logo" />
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className="about-text">
              <h2>شركة متخصصة في مجال <br /> <span className="text-myPrimary">الدعايا والأعلان.</span></h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <p>{props.data && props.data.paragraph1}</p>
              <p>{props.data && props.data.paragraph2}</p>
              <p>{props.data && props.data.paragraph3}</p>
              {/* <p>اسكندر هي شركة متخصصة في مجال الدعايا والأعلان من تسويق، تصميمات، طباعة، نعمل على مساعدة العملاء والأنشطة والشركات بجميع الأحجام على إنشاء وتنمية وجودهم عبر الأنترنت وفي الدعايا الداخلية والخارجية المتكاملة.</p> */}
              {/* <p>تأسست الشركة في 2020 بهدف توفير حلول تسويق شاملة وموجهة لعملائنا.</p> */}
              {/* <p>من وراء الكواليس يتكون فريقنا من محترفين ذوى خبرة في مجموعة واسعة من التخصصات</p> */}
              <h3>الخدمات :</h3>
              <div className="list-style row">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};