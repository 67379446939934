import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logoHorizontal from "../img/logo-isk-horizontal-1.png";

export default function Navigation() {
  return (
    <nav id="menu" className="navbar navbar-default navbar-expand-lg position-fixed">
  <div class="container">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <a className="navbar-brand ms-auto" href="#">
      <div className="">
        <img src={logoHorizontal} className="navigation-img" alt="logo" />
      </div>
    </a>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" data>
      <ul class="navbar-nav  mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">الرئيسية</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#about" >معلومات عنا</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#contact-us">تواصل معنا</a>
        </li>
      </ul>
    </div>
    <div className="">
      <a className="float-whatsapp-icon" href="https://wa.me/201228277208" rel="noreferrer"  target="_blank">
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon " style={{color: "#fff",}} />
      </a>
    </div>

  </div>
    </nav>
  );
};