import React, { useState, useEffect } from "react";
import Navigation from "./components/navigation";
import About from "./components/about";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./css/style.css";
import "./css/media.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div dir={"rtl"}>
      <Navigation />
      <About data={landingPageData.About} />
      <ContactUs data={landingPageData.ContactUs}/>
      <Footer/>
    </div>
  );
};

export default App;
